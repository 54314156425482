import { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { EditorProps } from '../../../form-builder/FormBuilderTypes';
import Checkbox from '../../../shared/form-control/Checkbox';
import ActionEditorBase from '../ActionEditorBase';
import ShowInResourceView from '../ShowInResourceView';

const YesNoResourceEditor: FC<EditorProps> = (props) => {
  const { action, patchData } = props;
  const { enableNA } = action?.data || {};
  const { t } = useTranslation('form-builder');

  const onNAChange = useCallback(
    (value: boolean) => {
      patchData({ enableNA: value });
    },
    [patchData],
  );

  return (
    <div data-cy="yes-no-editor">
      <ActionEditorBase {...props}></ActionEditorBase>

      <Checkbox value={enableNA ?? false} onChange={onNAChange} label={t('action-properties.yesno.enableNA')} className="my-4" />

      <ShowInResourceView {...props} />
    </div>
  );
};

export default YesNoResourceEditor;
